export type FileLocation = {
  key: string
  bucket: string
}

export enum FileTypes {
  'application/pdf' = 'application/pdf',
}

export type LowerCasePaperFormat =
  | 'letter'
  | 'legal'
  | 'tabloid'
  | 'ledger'
  | 'a0'
  | 'a1'
  | 'a2'
  | 'a3'
  | 'a4'
  | 'a5'
  | 'a6'

export type FilePaperSettings = {
  format: Uppercase<LowerCasePaperFormat>
  margin?: {
    top?: string | number
    bottom?: string | number
    left?: string | number
    right?: string | number
  }
}

export type APP_SCREEN_NAMES =
  | 'today-appointments'
  | 'appointments'
  | 'pumps'
  | 'patients'
  | 'users'
  | 'clinics'
  | 'patient/previous-sessions'
  | 'patient/cancelled-appointments'
  | 'patient/questionnaire-console'
  | 'patient/questionnaire-history'
  | 'patient/questionnaire-assignments'
