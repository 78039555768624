import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Logo } from 'components/Logo'
import * as Toast from 'components/Toast'
import { useAuth, useTitle } from 'hooks'
import { isEmail } from 'lib/utils'
import { Link, Navigate } from 'react-router-dom'

export const SignIn = () => {
  useTitle('Log In')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { signin, cleanupSession, token } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    reset()
  }, [reset])

  useEffect(() => {
    if (error) {
      Toast.error(error)
    }
  }, [error])

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      setError('')
      cleanupSession()
      await signin(data)
    } catch (error: any) {
      setError(error.description)
    } finally {
      setLoading(false)
    }
  }

  if (token) {
    return <Navigate to='/' replace />
  }

  return (
    <div className='flex w-full flex-col xl:flex-row-reverse h-full justify-center'>
      <div className='flex items-center w-full justify-center md:shadow-none xl:shadow-[-12px_0px_40px_-10px_rgba(109,109,109,0.08)]'>
        <div className='max-w-xs transform duration-200 hover:scale-110 cursor-pointer '>
          <Logo/>
        </div>
      </div>
      <div className='flex items-center w-full xl:max-w-screen-sm  h-full pb-40 xl:pb-0'>
        <div className='prose lg:prose-lg xl:prose max-w-none md:px-12 xl:px-24 xl:max-w-2xl w-full'>
          <h1>Log In</h1>
          <div className='mt-12 w-full'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Input
                  type='email'
                  label='Email address'
                  placeholder='E-mail Address'
                  autoComplete='new-password'
                  {...register('email', {
                    validate: (value) => isEmail(value) || 'Enter a valid e-mail address',
                  })}
                  error={errors?.email?.message}
                />
              </div>
              <div className='mt-2'>
                <Input
                  type='password'
                  label='Password'
                  placeholder='Password'
                  autoComplete='new-password'
                  {...register('password', {
                    required: 'Password is required and cannot be blank.',
                  })}
                  error={errors?.password?.message}
                />
              </div>
              <div className='mt-10'>
                <Button fullWidth type='submit' disabled={loading || isSubmitting}>
                  Log In
                </Button>
              </div>
            </form>
            <div className='mt-4 text-center'>
              <Link to="/forgot-password">
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
