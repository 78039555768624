import React, { useMemo, useState } from 'react'
import { Assignment, FormUserType, QUESTIONNAIRE_ASSIGNMENT_STATUS } from 'types'
import { useUserSettings } from 'hooks/useUserSettings'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { useAssignmentList } from 'hooks/useAssignment'
import { formatDate } from 'lib/day'
import { AssignmentStatus } from './components/AssignmentStatus'
import { Button } from 'components/Button'
import { useNavigate } from 'react-router-dom'
import { Table } from 'components/Table'
import Pagination from 'components/Pagination'

const PAGE_SIZE = 10

interface QuestionnaireAssignmentsProps {
  patientId: string
}

interface QuestionnaireAssignmentsTable {
  id: string
  name: string
  dueDate: string
  status: {
    value: QUESTIONNAIRE_ASSIGNMENT_STATUS
    render: JSX.Element
  }
  action: {
    value: string
    render: JSX.Element
  }
}

const defaultColumns: IColumn<QuestionnaireAssignmentsTable>[] = [
  { label: 'Name', key: 'name' },
  { label: 'Due Date', key: 'dueDate' },
  { label: 'Status', key: 'status' },
  { label: 'Action', key: 'action' },
]

export const QuestionnaireAssignments: React.FC<QuestionnaireAssignmentsProps> = ({ patientId }) => {
  const { getSortListingByScreen, getColumns, saveSortListing, saveColumns } = useUserSettings()
  const [columns, setColumns] = useState(
    getColumns<IColumn<QuestionnaireAssignmentsTable>[]>('patient/questionnaire-assignments') || defaultColumns,
  )
  const sortListingDefaults = getSortListingByScreen('patient/questionnaire-assignments')
  const [query, setQuery] = useState({
    patientId,
    ...[
      QUESTIONNAIRE_ASSIGNMENT_STATUS.QUEUED,
      QUESTIONNAIRE_ASSIGNMENT_STATUS.STARTED,
      QUESTIONNAIRE_ASSIGNMENT_STATUS.OVERDUE,
    ].reduce((acc: Record<string, string>, status, idx) => {
      acc[`status[${idx}]`] = status
      return acc
    }, {}),
    'participants[]': FormUserType.PATIENT,
    size: PAGE_SIZE,
    page: 0,
    orderby_field: sortListingDefaults?.fieldName || 'name',
    orderby_order: sortListingDefaults?.order || 'DESC',
  })
  const navigate = useNavigate()
  const { data, isLoading } = useAssignmentList(query, { ignore: !patientId })

  const assignmentRows: QuestionnaireAssignmentsTable[] = useMemo(() => {
    return (
      data?.data.map((assignment: Assignment) => ({
        id: assignment.id,
        name: assignment.form.name,
        dueDate: formatDate(assignment.dueDate),
        status: {
          value: assignment.status,
          render: <AssignmentStatus status={assignment.status} answers={assignment.answers} />,
        },
        action: {
          value: assignment.form.type,
          render: (
            <Button
              disabled={!assignment.active}
              onClick={() =>
                navigate(
                  `/form/${assignment.form.type}?version=v${assignment.form.version}&assignmentId=${assignment.id}`,
                )
              }
            >
              Start
            </Button>
          ),
        },
      })) || []
    )
  }, [data?.data, navigate])

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<QuestionnaireAssignmentsTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'patient/questionnaire-assignments',
      columns: tableSettings.columns,
    })
  }

  const orderBy = useMemo(() => {
    return { fieldName: query.orderby_field, order: query.orderby_order } as IOrderBy<QuestionnaireAssignmentsTable>
  }, [query.orderby_field, query.orderby_order])

  const onOrderBy = (options: IOrderBy<QuestionnaireAssignmentsTable>) => {
    setQuery({
      ...query,
      orderby_field: options.fieldName,
      orderby_order: options.order,
    })

    saveSortListing({
      screenName: 'patient/questionnaire-assignments',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  if (isLoading || !data?.data) {
    return null
  }

  return (
    <>
      <div className='shadow-md rounded-lg'>
        <div>
          <Table
            className='w-full'
            title={'Questionnaires'}
            showSearch={false}
            columns={columns}
            rows={assignmentRows}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            onSaveSettings={onSaveTableSettings}
          />
        </div>
      </div>
      <div className='w-full flex justify-end'>
        <Pagination
          currentPage={data.page + 1}
          totalPages={Math.ceil(data.total / data.size)}
          onPageChange={(n) => setQuery({ ...query, page: n - 1 })}
        />
      </div>
    </>
  )
}
