import React, { useEffect, useState } from 'react'
import { KeyedMutator } from 'swr'
import { ToggleSwitch } from 'components/ToggleSwitch'
import { assignmentRules as AssignmentRulesActions } from 'data'

interface Props {
  isActive: boolean
  patientId: string
  assignmentRuleId: string
  mutate: KeyedMutator<any>
  setLoading: (x: boolean) => void
}

export const AssignmentActive = ({ isActive, patientId, assignmentRuleId, mutate, setLoading }: Props) => {
  const [active, setActive] = useState(isActive)

  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  const onChange = async (event: { target: any; type?: any }) => {
    setLoading(true)

    const value = event.target.value

    setActive(value)

    await AssignmentRulesActions.activateAssignmentRule({ patientId, id: assignmentRuleId, active: value })

    await mutate()

    setLoading(false)
  }

  return <ToggleSwitch checked={active} onChange={onChange} label={active ? 'Yes' : 'No'} />
}
