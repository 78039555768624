import useSWR from 'swr'
import { assignments } from 'data'

export const useAssignment = (query: any, { ignore, ...options } = { ignore: false }) => {
  const { data, error, isLoading, mutate } = useSWR(
    !ignore ? ['/assignments', query] : null,
    ([, query]: any[]) => assignments.fetch(query),
    options,
  )

  return {
    data,
    error,
    mutate,
    isLoading,
    loading: !error && !data,
  }
}

export const useAssignmentList = (query: any, { ignore, ...options } = { ignore: false }) => {
  const { data, error, isLoading, mutate } = useSWR(
    !ignore ? ['/assignments', query] : null,
    ([, query]: any[]) => assignments.fetchList(query),
    options,
  )

  return {
    data,
    error,
    mutate,
    isLoading,
    loading: !error && !data,
  }
}
