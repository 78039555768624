import { Button } from 'components/Button'
import Pagination from 'components/Pagination'
import { Table } from 'components/Table'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { useAssignmentList } from 'hooks/useAssignment'
import { useUserSettings } from 'hooks/useUserSettings'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Assignment, FormUserType, QUESTIONNAIRE_ASSIGNMENT_STATUS } from 'types/dist'

const PAGE_SIZE = 10

interface QuestionnaireFormProps {
  patientId: string
  role?: FormUserType
}

interface QuestionnairesTable {
  id: string
  name: string
  completeDate: string
  action: {
    value: string
    render: JSX.Element
  }
}

const defaultColumns: IColumn<QuestionnairesTable>[] = [
  { label: 'Name', key: 'name' },
  { label: 'Date Completed', key: 'completeDate' },
  { label: 'Action', key: 'action' },
]

export const QuestionnaireHistory: React.FC<QuestionnaireFormProps> = ({ patientId, role }) => {
  const { getSortListingByScreen, getColumns, saveSortListing, saveColumns } = useUserSettings()
  const [columns, setColumns] = useState(
    getColumns<IColumn<QuestionnairesTable>[]>('patient/questionnaire-history') || defaultColumns,
  )
  const sortListingDefaults = getSortListingByScreen('patient/questionnaire-history')
  const [query, setQuery] = useState({
    patientId,
    status: QUESTIONNAIRE_ASSIGNMENT_STATUS.COMPLETED,
    participants: role ? [role] : [],
    size: PAGE_SIZE,
    page: 0,
    orderby_field: sortListingDefaults?.fieldName || 'name',
    orderby_order: sortListingDefaults?.order || 'DESC',
  })
  const navigate = useNavigate()

  const { data, isLoading } = useAssignmentList(query, { ignore: !patientId })

  const forms: QuestionnairesTable[] = data?.data.map((x: Assignment) => ({
    name: x.form.name,
    completeDate: x.completeDate ? new Date(x.completeDate).toLocaleDateString() : '',
    action: {
      value: x.form.type,
      render: (
        <Button onClick={() => navigate(`/form/${x.form.type}?version=v${x.form.version}&assignmentId=${x.id}`)}>
          View
        </Button>
      ),
    },
  }))

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<QuestionnairesTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'patient/questionnaire-history',
      columns: tableSettings.columns,
    })
  }

  const orderBy = useMemo(() => {
    return { fieldName: query.orderby_field, order: query.orderby_order } as IOrderBy<QuestionnairesTable>
  }, [query.orderby_field, query.orderby_order])

  const onOrderBy = (options: IOrderBy<QuestionnairesTable>) => {
    setQuery({
      ...query,
      orderby_field: options.fieldName,
      orderby_order: options.order,
    })

    saveSortListing({
      screenName: 'patient/questionnaire-history',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  if (isLoading || !data?.data) {
    return null
  }

  return (
    <>
      <div className='shadow-md rounded-lg'>
        <div>
          <Table
            className='w-full'
            title={'Questionnaire History'}
            showSearch={false}
            columns={columns}
            rows={forms}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            onSaveSettings={onSaveTableSettings}
          />
        </div>
      </div>
      <div className='w-full flex justify-end [&>nav]:mt-0'>
        <Pagination
          currentPage={data.page + 1}
          totalPages={Math.ceil(data.total / data.size)}
          onPageChange={(n) => setQuery({ ...query, page: n - 1 })}
        />
      </div>
    </>
  )
}
