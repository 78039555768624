import React from 'react'
import { FormUserType } from 'types/dist'
import { useAuth, useTitle } from '../../../hooks'
import { QuestionnaireHistory } from '../Forms/QuestionnaireHistory'

export const PatientAssignmentsHistoryScreen = () => {
  useTitle('Questionnaire History')
  const { userDetail } = useAuth()

  const patientId = userDetail?.patientId as string

  return (
    <div className='[&_nav]:mt-5'>
      <QuestionnaireHistory patientId={patientId} role={FormUserType.PATIENT} />
    </div>
  )
}
