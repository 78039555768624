import { PaginatedQuery } from "./pagination"

export type Patient = {
  id: string
  externalId: string | null
  firstName: string
  lastName: string
  email?: string | null
  mrn?: string | null
  dob: string
  phone: string
  race: string
  gender: string
  address?: PatientAddress | null
  emergencyContact?: PatientEmergencyContact | null
  isLocalEMR?: boolean
  isReadOnly?: boolean
  status?: PatientStatus
  created?: Date
  updated?: Date
}

export enum PatientStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type PatientAddress = {
  street: string
  city: string
  state?: string
  zip?: string
}
export type PatientEmergencyContact = {
  firstName: string
  lastName: string
  phone: string
  relationship?: string
}

export type CreatePatientDTO = PatientModelEntity & {
  id?: string
  clinicIds?: string[]
}
export type UpdatePatientDTO = Omit<PatientModelEntity, 'id'>

export type CreatePatientResponseDTO = {
  patientId: string
}

export type GeneratePatientDTO = Patient
export type GeneratePatientsDTO = Partial<CreatePatientDTO> & {
  clinicIds?: string[],
  count: number
}

export type PatientOutput = Patient

export type PatientModelEntity = Partial<Patient> & {
  clinicIds?: string[]
  statusChangeReason?: string
}

export type PatientOutputDTO = PatientModelEntity & {
  clinics?: { id: string, name: string }[]
  user?: { id: string }
}

export type PatientQuery = PaginatedQuery<{
  search?: string
  firstName?: string
  lastName?: string
  dob?: string
  clinicIds?: string[]
  status?: PatientStatus
}>

export type PatientOrderByQuery = {
  fieldName: 'firstName' | 'lastName' | 'dob' | 'phone' | 'email'
  order: 'ASC' | 'DESC'
}
