import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { Logo } from 'components/Logo'
import { Avatar } from 'components/Avatar'
import { navBarConfig } from 'components/Navbar/config'
import { DropdownMenu } from 'components/Navbar/DropdownMenu'
import { SelectClinicContainer } from 'components/Modals/SelectClinic/SelectClinicContainer'
import { useIsRole } from 'hooks/useRole'
import { USER_ROLE } from 'types'

const BASE_OPTIONS = [{ value: 'logout', label: 'Logout', adminOnly: false }]
const MENU_OPTIONS = [
  { value: 'change-clinic', label: 'Change clinic', className: 'border-b-2 mb-4', adminOnly: false },
  { value: 'clinics', label: 'Clinics', adminOnly: true },
  { value: 'users', label: 'Users', adminOnly: true },
  { value: 'fields-management', label: 'Fields Management', adminOnly: true },
  ...BASE_OPTIONS,
]

export const Navbar = () => {
  const [showSelectClinic, setShowSelectClinic] = useState(false)
  const navigate = useNavigate()
  const auth = useAuth()
  const [navHeight, setNavHeight] = useState(0)
  const { menuLeft } = navBarConfig
  const { pathname, search } = useLocation()
  const navRef = useRef<HTMLDivElement>(null)
  const isAdmin = auth?.userDetail?.role === 'administrator'
  const isPatient = useIsRole(USER_ROLE.PATIENT)

  useEffect(() => {
    if (navRef.current) {
      setNavHeight(navRef?.current?.getBoundingClientRect().height)
    }
  }, [navRef])

  const dropDownOptions = useMemo(() => {
    if (isPatient) return BASE_OPTIONS
    if (isAdmin) return MENU_OPTIONS
    return MENU_OPTIONS.filter(option => !option.adminOnly)
  }, [isAdmin, isPatient])

  const menuActions = (option: string) => {
    switch (option) {
      case 'logout':
        auth.signout()
        break
      case 'change-clinic':
        setShowSelectClinic(true)
        break
      case 'users':
        navigate('/users')
        break
      case 'fields-management':
        navigate('/fields-management')
        break
      case 'clinics':
        navigate('/clinics')
        break
    }
  }

  const clinicName = useMemo(() => {
    const foundClinic = auth.userDetail?.memberships.find(membership => membership.clinic.id === auth.clinicId)?.clinic

    return foundClinic?.name
  }, [auth.userDetail, auth.clinicId])

  const onCloseSelectClinic = useCallback(() => setShowSelectClinic(false), [])

  const renderNavItem = (url: string, label: React.JSX.Element) => {
    return (
      <NavLink
        key={url}
        to={url}
        className={({ isActive }) => {
          return isActive ? 'border border-accent rounded-md px-3 py-1' : ''
        }}
      >
        <span className='text-neutral group-hover:text-white font-medium capitalize'>{label}</span>
      </NavLink>
    )
  }

  return (
    <div className='print:hidden'>
      <div ref={navRef} className='fixed bg-white p-2 shadow-md w-full flex items-center justify-between z-[52]'>
        <div className='flex items-center gap-4'>
          <Logo className='h-12' />
          {!isPatient && menuLeft.filter(item => !item.patientOnly).map(({ label, url, adminOnly }) => {
            if (adminOnly && auth?.userDetail?.role !== 'administrator') return null

            const baseUrl = `/clinics/${auth.clinicId}${url}`
            const isRootActive = pathname === baseUrl 
            const goToUrl = baseUrl.concat(isRootActive ? `${search}` : '')

            return renderNavItem(goToUrl, label)
          })}

          {isPatient && menuLeft.filter(item => item.patientOnly).map(({ label, url }) => renderNavItem(url, label))}
        </div>

        <div className='flex gap-2 font-medium items-center'>
          <div className='cursor-pointer md:max-w-[200px] lg:max-w-[400px] lg:bg-white whitespace-nowrap overflow-hidden text-ellipsis' onClick={() => setShowSelectClinic(true)}>{clinicName}</div>

          <div className='flex items-center'>
            <DropdownMenu options={dropDownOptions} onSelect={(value) => menuActions(value)}>
              <Avatar src={auth?.user?.picture} className='w-8 cursor-pointer' />
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div style={{ height: `${navHeight}px`, width: '100%' }}></div>

      {showSelectClinic && (
        <SelectClinicContainer
          title="Select Clinic"
          onClose={onCloseSelectClinic}
        />
      )}
    </div>
  )
}
