import React from 'react'
import { useAuth, useTitle } from 'hooks'
import { QuestionnaireAssignments } from 'pages/private/Forms/QuestionnaireAssignments'

export const PatientAssignmentsScreen = () => {
  useTitle('Questionnaires')
  const { userDetail } = useAuth()
  const patientId = userDetail?.patientId as string

  return (
    <QuestionnaireAssignments patientId={patientId} />
  )
}
