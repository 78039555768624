import React from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { FormResult, MultipleQuestion as MultipleQuestionType, Question, QuestionType } from 'types/dist'
import { ConditionalSelectQuestion } from './components/ConditionalSelectQuestion'
import { MultipleQuestion } from './components/MultipleQuestion'
import { ScoreQuestion } from './components/ScoreQuestion'
import { SelectQuestion } from './components/SelectQuestion'
import { TextQuestion } from './components/TextQuestion'

interface ComponentProps {
  currentQuestion: number
  disabled?: boolean
  type?: 'question' | 'answer'
  parentQuestion?: MultipleQuestionType
  questions: Question[]
  value: FormResult
  setValue: UseFormSetValue<FormResult>
}

function formatAnswer(question: Question, value: FormResult): string {
  const answer = value?.[question.key]
  switch (question.type) {
    case QuestionType.SELECT:
      if (question.multipleAnswers && Array.isArray(answer)) {
        return answer
          .map((val: string) => question.variants.find((variant) => variant.value === val)?.label || val)
          .join(', ')
      } else {
        return question.variants.find((variant) => variant.value === answer)?.label || answer
      }

    case QuestionType.SCORE:
      return `${answer} / ${question.maxValue}`

    case QuestionType.TEXT:
      return answer

    case QuestionType.MULTIPLE:
      if (answer && typeof answer === 'object') {
        return question.questions
          .map((subQuestion) => {
            const subAnswer = formatAnswer(subQuestion, answer)
            return `${subQuestion.title}: ${subAnswer}`
          })
          .join('; ')
      } else {
        return ''
      }

    case QuestionType.CONDITIONAL_SELECT:
      // eslint-disable-next-line no-case-declarations
      let result = question.variants.find((variant) => variant.value === answer)?.label || answer

      if (answer === question.showIf) {
        const subQuestion = question.question
        const subSubAnswer = formatAnswer(subQuestion, value)
        result += `; ${subQuestion.title}: ${subSubAnswer}`
      }

      return result

    default:
      return answer
  }
}

export const FormComponentRenderer = ({
  currentQuestion,
  questions,
  disabled,
  parentQuestion,
  type = 'question',
  value,
  setValue,
}: ComponentProps) => {
  const question = questions[currentQuestion]

  return type === 'question' ? (
    <div className={`${parentQuestion ? 'mb-0' : 'mb-12'} ${disabled ? 'text-slate-400' : ''}`}>
      <div className={`font-semibold ${parentQuestion ? 'text-md' : 'text-xl'} mb-6 text-black`}>{question.title}</div>
      <div>
        {question.type === QuestionType.SELECT ? (
          <SelectQuestion
            question={question}
            value={value?.[question.key]}
            parentQuestionKey={parentQuestion?.key}
            setValue={setValue}
            disabled={disabled}
          />
        ) : question.type === QuestionType.SCORE ? (
          <ScoreQuestion question={question} value={value?.[question.key]} setValue={setValue} disabled={disabled} />
        ) : question.type === QuestionType.TEXT ? (
          <TextQuestion question={question} value={value?.[question.key]} setValue={setValue} disabled={disabled} />
        ) : question.type === QuestionType.MULTIPLE ? (
          <MultipleQuestion question={question} value={value?.[question.key]} setValue={setValue} disabled={disabled} />
        ) : question.type === QuestionType.CONDITIONAL_SELECT ? (
          <ConditionalSelectQuestion
            question={question}
            value={value}
            setValue={setValue}
            disabled={disabled}
            parentQuestion={parentQuestion}
          />
        ) : (
          <div></div>
        )}
      </div>
      <div className='whitespace-pre-wrap mt-5'>
        <i>{question.description}</i>
      </div>
    </div>
  ) : question.type === QuestionType.MULTIPLE ? (
    <div className='text-lg mb-4'>
      <span style={{ maxWidth: 800 }}>{question.title}</span>
      <div className={'mt-4'}>
        {formatAnswer(question, value)
          .split('; ')
          .map((answer, i) => {
            const [title, value] = answer.split(': ')
            return (
              <div className='flex justify-between' key={`${question.key}_sub_${i}`}>
                <span style={{ maxWidth: 800 }} className='font-semibold'>
                  {title}
                </span>
                <span className='text-primary font-semibold'>{value}</span>
              </div>
            )
          }) || '-'}
      </div>
    </div>
  ) : (
    <div className='flex justify-between text-lg mb-4'>
      <span style={{ maxWidth: 800 }}>{question.title}</span>
      <span className='text-primary font-semibold'>{formatAnswer(question, value) || '-'}</span>
    </div>
  )
}
