import React from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { FormResult, SelectQuestion as SelectQuestionType } from 'types/dist'
import { Select } from '../../../../components/Select'

interface Props {
  question: SelectQuestionType
  parentQuestionKey?: string
  value: string | number
  setValue: UseFormSetValue<FormResult>
  disabled?: boolean
}

export const SelectQuestion = ({ question, value, setValue, parentQuestionKey, disabled }: Props) => {
  const handleSelectChange = (variant: string) => {
    if (question.multipleAnswers) {
      const currentValue: string[] = Array.isArray(value) ? value : []
      const updatedValue = currentValue.includes(variant)
        ? currentValue.filter((v) => v !== variant)
        : [...currentValue, variant]

      setValue(question.key, updatedValue.length ? updatedValue : undefined)
    } else {
      setValue(question.key, variant)
    }
  }

  const isOtherChecked = !!(typeof value === 'string' && question.multipleAnswers)

  return question.multipleAnswers ? (
    <div>
      {question.variants.map((variant, i) => (
        <div className='flex items-center my-3 text-lg' key={`${parentQuestionKey || ''}_${question.key}_${i}`}>
          <input
            id={`${parentQuestionKey || ''}_${question.key}_${i}`}
            type='checkbox'
            disabled={disabled}
            className={`checkbox rounded-none border-2 checkbox-primary`}
            checked={Array.isArray(value) && value.includes(variant.value)}
            onChange={() => handleSelectChange(variant.value)}
          />
          <label
            className={`ml-3 ${Array.isArray(value) && value.includes(variant.value) ? 'text-black' : ''} ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
            htmlFor={`${parentQuestionKey || ''}_${question.key}_${i}`}
          >
            {variant.label}
          </label>
        </div>
      ))}
      {question.provideOther && (
        <div className='flex items-center my-3 text-lg' key={`${parentQuestionKey || ''}_${question.key}_other`}>
          <input
            id={`${parentQuestionKey || ''}_${question.key}_other`}
            type='checkbox'
            disabled={disabled}
            className='checkbox rounded-none border-2 checkbox-primary'
            checked={isOtherChecked}
            onChange={() => setValue(question.key, '')}
          />
          <label
            className={`ml-3 ${isOtherChecked ? 'text-black' : ''} ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
            htmlFor={`${parentQuestionKey || ''}_${question.key}_other`}
          >
            Other
          </label>
          <input
            type='text'
            disabled={disabled || !isOtherChecked}
            value={typeof value === 'string' ? value : ''}
            placeholder={'Specify...'}
            onChange={(e) => {
              const text = e.target.value
              setValue(question.key, text)
            }}
            className={`ml-3 w-96 border-0 border-b-2 text-black ${disabled || !isOtherChecked ? 'border-slate-400' : 'border-black'} focus:outline-none`}
          />
        </div>
      )}
    </div>
  ) : (
    <Select
      value={value}
      options={question.variants.map(({ label, value }) => ({ label, value }))}
      onSelect={(variant) => setValue(question.key, variant)}
      name={`${parentQuestionKey || ''}_${question.key}`}
      disabled={disabled}
    />
  )
}
