import React, { ReactNode, createContext, useCallback } from 'react'
import { isBoolean } from 'lodash'
import * as auth from 'lib/auth'
import { useLocalStorage } from 'hooks'
import { Field, USER_ROLE, UserDetail } from 'types'
import { trackLogout } from 'data/user-history'

interface Credentials {
  email: string
  password: string
}

interface User {
  sub: string
  nickname: string
  picture: string
  email: string
  updated_at: string
}

interface AuthContextType {
  clinicId: string | null
  user: User | null
  userDetail: UserDetail | null
  token: string | null
  isNextAppointmentDetailsCollapsed: boolean
  enableLocalEmr: boolean
  setSession: ({ user, token, userDetail, clinicId, enableLocalEmr, fieldsDictionary }: any) => void
  signin: (data: Credentials) => void
  signout: () => void
  cleanupSession: () => void
  fieldsDictionary: Record<string, Field>,
  role: USER_ROLE | null
}

export const AuthContext = createContext<AuthContextType>({
  clinicId: null,
  user: null,
  userDetail: null,
  token: null,
  isNextAppointmentDetailsCollapsed: true,
  enableLocalEmr: false,
  role: null,
  setSession: () => {},
  signin: () => {},
  signout: () => {},
  cleanupSession: () => {},
  fieldsDictionary: {}
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useLocalStorage('user', null)
  const [userDetail, setUserDetail] = useLocalStorage('userDetail', null)
  const [token, setToken] = useLocalStorage('token', null)
  const [clinicId, setClinicId] = useLocalStorage('clinicId', null)
  const [isNextAppointmentDetailsCollapsed, setIsNextAppointmentDetailsCollapsed] = useLocalStorage('isNextAppointmentDetailsCollapsed', true)
  const [enableLocalEmr, setEnableLocalEmr] = useLocalStorage('enableLocalEmr', false)
  const [role, setRole] = useLocalStorage('role', null)
  const [fieldsDictionary, setFieldsDictionary] = useLocalStorage('fields', null)

  const setSession = useCallback(({
    user,
    token,
    userDetail,
    clinicId,
    isNextAppointmentDetailsCollapsed,
    enableLocalEmr,
    fieldsDictionary
  }: {
    user?: User
    token?: string
    userDetail?: UserDetail
    clinicId?: string
    isNextAppointmentDetailsCollapsed?: boolean
    enableLocalEmr?: boolean
    fieldsDictionary?: AuthContextType["fieldsDictionary"]
  }) => {
    user && setUser(user)
    token && setToken(token)
    userDetail && setUserDetail(userDetail)
    clinicId && setClinicId(clinicId)
    fieldsDictionary && setFieldsDictionary(fieldsDictionary)

    if (userDetail?.role && !role) {
      setRole(userDetail.role)
    }
    if (isBoolean(isNextAppointmentDetailsCollapsed)) {
      setIsNextAppointmentDetailsCollapsed(isNextAppointmentDetailsCollapsed)
    }
    if (isBoolean(enableLocalEmr)) {
      setEnableLocalEmr(enableLocalEmr)
    }
  }, [setClinicId, setEnableLocalEmr, setFieldsDictionary, setIsNextAppointmentDetailsCollapsed, setToken, setUser, setUserDetail, setRole, role])

  const signin = (data: Credentials) => {
    return auth.signin(data)
  }

  const cleanupSession = useCallback(() => {
    setUser(null)
    setToken(null)
    setUserDetail(null)
    setClinicId(null)
    setFieldsDictionary(null)
    setEnableLocalEmr(null)
    setRole(null)
  }, [setClinicId, setEnableLocalEmr, setFieldsDictionary, setToken, setUser, setUserDetail, setRole])

  const signout = () => {
    trackLogout()
    cleanupSession()
    auth.signout()
  }

  const value: AuthContextType = {
    clinicId,
    token,
    user,
    userDetail,
    isNextAppointmentDetailsCollapsed,
    enableLocalEmr,
    fieldsDictionary,
    role,
    setSession,
    signin,
    signout,
   cleanupSession 
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
