import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Button } from 'components/Button'
import * as Toast from 'components/Toast'
import { assignments as AssignmentActions } from 'data'
import { KeyedMutator } from 'swr'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface Props {
  currentDueDate: string
  patientId: string
  assignmentId: string
  mutate: KeyedMutator<any>
  setLoading: (x: boolean) => void
}

type InputProps = {
  onClick?: React.MouseEventHandler<HTMLSpanElement>
}

export const AssignmentDueDate = ({ currentDueDate, patientId, assignmentId, mutate, setLoading }: Props) => {
  const [calendarDate, setCalendarDate] = useState<Date | null>(new Date(currentDueDate))
  const [dueDate, setDueDate] = useState(currentDueDate)

  useEffect(() => {
    setDueDate(currentDueDate)
    setCalendarDate(new Date(currentDueDate))
  }, [currentDueDate])

  const calRef = useRef<DatePicker | null>(null)

  const Input = forwardRef<HTMLSpanElement, InputProps>(({ onClick }, ref) => (
    <span className={'flex text-base items-center cursor-pointer'} onClick={onClick} ref={ref}>
      <CalendarIcon className={'mr-2'} />
      {new Date(dueDate).toLocaleDateString()}
    </span>
  ))

  const onChange = async () => {
    if (!calendarDate) {
      return
    }

    setLoading(true)

    const utcDate = new Date(
      Date.UTC(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        calendarDate.getHours(),
        0,
        0,
        0,
      ),
    )

    setDueDate(new Date(dayjs(utcDate).utc().format('MMM DD, YYYY')).toISOString())

    await AssignmentActions.updateDueDate({ id: assignmentId, patientId, dueDate: utcDate.toISOString() })

    await mutate()

    Toast.success('Due date updated successfully.')
    console.log(utcDate.toISOString())

    setLoading(false)
  }

  return (
    <DatePicker
      shouldCloseOnSelect={false}
      selected={calendarDate}
      onChange={(date) => date && setCalendarDate(date)}
      showPopperArrow={false}
      ref={calRef}
      customInput={<Input />}
      minDate={new Date()}
      popperClassName={'!shadow-2xl !rounded-xl'}
      calendarClassName={'!border-0 !rounded-xl'}
      dayClassName={(date) => {
        if (date.toDateString() === calendarDate?.toDateString()) {
          return '!bg-primary !text-white'
        }
        return ''
      }}
      onClickOutside={() => setCalendarDate(new Date(dueDate))}
    >
      <div className='flex w-full space-x-1 mb-4'>
        <Button
          className='flex-1 min-h-0 h-8 font-normal'
          onClick={() => {
            setCalendarDate(new Date(dueDate))

            calRef.current?.setOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          className='flex-1 min-h-0 h-8 font-normal'
          onClick={async () => {
            await onChange()

            calRef.current?.setOpen(false)
          }}
          disabled={calendarDate?.toLocaleDateString() === new Date(dueDate).toLocaleDateString()}
        >
          OK
        </Button>
      </div>
    </DatePicker>
  )
}
