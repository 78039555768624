import { useEffect } from "react";
import { useAuth } from './useAuth'
import * as storage from 'lib/storage'

// Refresh browser if a session conflict is detected
// meaning having multiple tabs opened and trying to signIn with different profiles
export const useCheckSessionConflict = () => {
  const { userDetail } = useAuth()

  useEffect(() => {
    if (!userDetail?.id) return

    const handleFocus = () => {
      const userInStorage = storage.get('userDetail')

      if (userInStorage && userInStorage.id !== userDetail.id) {
        window.location.reload()
      }
    };

    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [userDetail]);
};