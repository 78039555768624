import React, { Dispatch, SetStateAction } from 'react'
import { ConditionalSelectQuestion, FormResult, MultipleQuestion, Question, QuestionType } from 'types/dist'
import { Button } from '../../../../components/Button'

interface FooterProps {
  questions: Question[]
  data: FormResult
  showSubmitScreen: boolean
  setShowSubmitScreen: Dispatch<SetStateAction<boolean>>
}

export const FormFooter = ({ questions, data, showSubmitScreen, setShowSubmitScreen }: FooterProps) => {
  const onSubmit = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation()

    if (!questions.some((x) => data?.[x.key] === undefined)) {
      setShowSubmitScreen(true)
      return
    }
  }

  function countTotalQuestions(questions: Question[], data: FormResult): number {
    let total = 0

    for (const question of questions) {
      if (question.type === QuestionType.MULTIPLE) {
        const multipleQuestion = question as MultipleQuestion

        total += countTotalQuestions(multipleQuestion.questions, data?.[question.key] || {})
      } else if (question.type === QuestionType.CONDITIONAL_SELECT) {
        const conditionalQuestion = question as ConditionalSelectQuestion

        total += 1

        const answer = data?.[conditionalQuestion.key]

        if (answer === conditionalQuestion.showIf) {
          total += countTotalQuestions([conditionalQuestion.question], data)
        }
      } else {
        total += 1
      }
    }

    return total
  }

  function countCompletedQuestions(questions: Question[], data: FormResult): number {
    let completed = 0

    for (const question of questions) {
      if (question.type === QuestionType.MULTIPLE) {
        const multipleQuestion = question as MultipleQuestion

        const subData = data?.[question.key] || {}

        completed += countCompletedQuestions(multipleQuestion.questions, subData)
      } else if (question.type === QuestionType.CONDITIONAL_SELECT) {
        const conditionalQuestion = question as ConditionalSelectQuestion
        const answer = data?.[conditionalQuestion.key]

        if (answer !== undefined && answer !== null) {
          completed += 1

          if (answer === conditionalQuestion.showIf) {
            completed += countCompletedQuestions([conditionalQuestion.question], data)
          }
        }
      } else {
        const answer = data?.[question.key]
        if (answer !== undefined && answer !== null) {
          completed += 1
        }
      }
    }

    return completed
  }

  const totalQuestions = countTotalQuestions(questions, data)
  const completedQuestions = countCompletedQuestions(questions, data)

  return (
    <div
      className='fixed bottom-0 left-0 w-full flex flex-col justify-center items-center px-24 py-6 bg-white shadow-lg'
      style={{
        borderTop: '1px solid #dee2e4',
        boxShadow: '0px -10px 15px 0px rgb(0,0,0,0.05)',
      }}
    >
      <div
        style={{
          width: '100%',
          marginBottom: 15,
          background: '#dee2e4',
          borderRadius: 5,
        }}
      >
        <div
          className='bg-primary'
          style={{
            height: 6,
            borderRadius: 5,
            transition: 'width .3s',
            width: `${completedQuestions * (100 / totalQuestions)}%`,
          }}
        />
      </div>
      <div className='flex w-full justify-between'>
        <div className='font-semibold text-xl'>
          {completedQuestions}/{totalQuestions}
        </div>
        <div>
          {showSubmitScreen && (
            <Button
              type='button'
              className='mr-3'
              onClick={() => {
                if (showSubmitScreen) {
                  setShowSubmitScreen(false)
                  return
                }
              }}
            >
              Back to Questions
            </Button>
          )}
          {showSubmitScreen ? (
            <Button key={'form-submit'} type='submit'>
              Submit Answers
            </Button>
          ) : (
            <Button
              key={'form-finish'}
              className='w-52 py-0'
              disabled={completedQuestions !== totalQuestions}
              type='button'
              onClick={onSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
