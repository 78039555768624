import React from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { FormResult, MultipleQuestion as MultipleQuestionType } from 'types/dist'
import { FormComponentRenderer } from '../renderer'

interface Props {
  question: MultipleQuestionType
  value: Record<string, any>
  disabled?: boolean
  setValue: UseFormSetValue<FormResult>
}

export const MultipleQuestion = ({ question, value, disabled, setValue }: Props) => {
  return (
    <div className='flex flex-col gap-4'>
      {question.questions.map((q, i) => (
        <FormComponentRenderer
          disabled={disabled}
          key={`multiple_question_${q.key}_${i}`}
          parentQuestion={question}
          currentQuestion={i}
          questions={question.questions}
          value={value}
          setValue={(key, val) => {
            const obj = value || {}

            setValue(question.key, { ...obj, [key]: val })
          }}
        />
      ))}
    </div>
  )
}
