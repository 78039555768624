import dayjs from 'dayjs'
import { InfusionSession } from 'types'
import { useFieldDictionary } from '../hooks/useFields'
import {
  gaugeOptions,
  insulinPumpOptions,
  ivSiteOptions,
} from 'pages/private/Flowsheet/constants'

export const hasValidIvTime = (ivTime?: string) => !!ivTime && dayjs(ivTime).isValid()
export const hasIvGauge = (ivGauge: string | undefined, values: string[]) =>
  ivGauge && values.includes(ivGauge)
export const hasIvSite = (ivSite: string | undefined, values: string[]) => ivSite && values.includes(ivSite)
export const hasInsulinPump = (insulinPump: string | undefined, values: string[]) =>
  insulinPump && values.includes(insulinPump)

export const useCheckSessionStartedFields = (data: InfusionSession) => {
  const { fieldValues } = useFieldDictionary()
  const ivGaugeValues = fieldValues('ivGauge', gaugeOptions).map(({ value }) => value)
  const ivSiteValues = fieldValues('ivSite', ivSiteOptions).map(({ value }) => value)
  const insulinPumpValues = fieldValues('insulinPump', insulinPumpOptions).map(({ value }) => value)

  return ![
    hasValidIvTime(data.ivTime),
    hasIvGauge(data.ivGauge, ivGaugeValues),
    hasIvSite(data.ivSite, ivSiteValues),
    hasInsulinPump(data.insulinPump, insulinPumpValues),
  ].some((value) => !value);
}
