import { USER_ROLE } from "types";
import { useMemo } from "react";
import { useAuth } from './useAuth'

export const getRoleInStorage = () => {
  try {
    return JSON.parse(localStorage.getItem('role') || '""')
  } catch {
    return ''
  }
}

export const useIsRole = (roleArg: USER_ROLE) => {
  const { role } = useAuth()
  const is = useMemo(() => roleArg === role, [role, roleArg])

  return is
}