import React, { useRef, useState } from 'react'
import _ from 'lodash'
import UserItem from 'components/UserItem'
import { Search } from 'components/Search'
import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { ReactComponent as Filter } from 'assets/icons/filter.svg'
import { useClinics, useTitle, useUsers } from 'hooks'
import { ClinicDetail } from 'types'
import Pagination from 'components/Pagination'

const DEFAULT_SEARCH_VALUES = { size: 5, page: 0, active: 'true', search: '' }

export const Users = () => {
  useTitle('Users')
  const [query, setQuery] = useState<any>(DEFAULT_SEARCH_VALUES)
  const [addUser, setAddUser] = useState(false)
  const { data, paginationData, mutate } = useUsers(JSON.parse(JSON.stringify(query)), {})
  const { data: paginatedData } = useClinics({})

  const clinics = paginatedData?.data || []

  const inputSearchRef = useRef<HTMLInputElement>(null)

  const refreshData = () => {
    mutate('/users')
  }

  const onInputSearchClear = () => {
    if (inputSearchRef.current)
      inputSearchRef.current.value = ''
    setQuery({ ...query, page: 0, search: ''})
  }

  const onClear = () => {
    if (inputSearchRef.current)
      inputSearchRef.current.value = ''
    setQuery(DEFAULT_SEARCH_VALUES)
  }

  return (
    <div className='w-full mx-auto h-full'>
      <div className=' p-6 shadow-md border-2 border-gray-50 my-6 rounded-lg sticky top-[50px] z-10 bg-white'>
        <div className='flex gap-2 mb-2'>
          <span className='text-2xl font-medium mr-8'>Users</span>
          <div className='flex-1'>
            <Search
              ref={inputSearchRef}
              placeHolder='Search User'
              className='w-full'
              onSearch={(e) => {
                setQuery({ ...query, page: 0, search: e })
              }}
              onClear={onInputSearchClear}
            />
          </div>

          <div className='flex-1'>
            <Dropdown
              key={'Clinic'}
              withAutocomplete
              placeholder={'Filter By Clinic'}
              options={
                Array.isArray(clinics)
                  ? clinics.map((clinic: ClinicDetail) => {
                    return { value: clinic?.id ?? '', label: clinic?.name ?? '' }
                  })
                  : []
              }
              icon={<Filter />}
              value={query['clinicIds[0]'] || ''}
              className='h-8'
              onChange={({ target: { value } }) => {
                setQuery({ ..._.omit(query, 'clinicIds[0]'), page: 0, ...(value && { 'clinicIds[0]': value }) })
              }}
            />
          </div>

          <div className='flex-1'>
            <Dropdown
              key={'Activity'}
              placeholder={'Filter By Activity'}
              options={[
                { label: 'In-Active', value: 'false' },
                { label: 'Active', value: 'true' },
              ]}
              icon={<Filter />}
              value={query.active || ''}
              className='h-8'
              onChange={({ target: { value } }) => {
                setQuery({ ..._.omit(query, 'active'), page: 0, ...(value && { active: value }) })
              }}
            />
          </div>

          <div className='flex-1'>
            <Dropdown
              key={'Role'}
              placeholder={'Filter By Role'}
              options={[
                { value: 'clinical-staff', label: 'Clinical Staff' },
                { value: 'patient', label: 'Patient' },
                { value: 'provider', label: 'Provider' },
                { value: 'administrator', label: 'Administrator' },
              ]}
              icon={<Filter />}
              value={query['roles[0]'] || ''}
              className='h-8'
              onChange={({ target: { value } }) => {
                setQuery({ ..._.omit(query,'roles[0]'), page: 0, ...(value && {'roles[0]': value }) })
              }}
            />
          </div>
        </div>

        <div className='flex gap-2 justify-end'>
          <Button className='min-h-8 h-8' onClick={onClear}>
            Clear
          </Button>

          <Button className='min-h-8 h-8 bg-secondary font-light' type='button' onClick={() => setAddUser(true)}>
            Add New User
          </Button>
        </div>
      </div>
      {addUser && <UserItem type='add' setUserAdded={setAddUser} refreshData={refreshData} />}

      <div className='hide-scrollbar'>
        {Array.isArray(data) &&
          data.map((user) => <UserItem key={user.id} user={user} type='list' refreshData={refreshData} />)}
        {Array.isArray(data) && data.length === 0 && (
          <div className='flex justify-center items-center h-full'>
            <span className='text-2xl font-medium'>No Users Found</span>
          </div>
        )}
        <div className='w-full flex justify-end'>
          <Pagination
            currentPage={paginationData.page + 1}
            totalPages={Math.ceil(paginationData.total / paginationData.size)}
            onPageChange={(n) => setQuery({ ...query, page: n - 1 })}
          />
        </div>
      </div>
    </div>
  )
}
