import React, { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import {
  ConditionalSelectQuestion as ConditionalSelectQuestionType,
  FormResult,
  MultipleQuestion as MultipleQuestionType,
  QuestionType,
} from 'types/dist'
import { FormComponentRenderer } from '../renderer'
import { SelectQuestion } from './SelectQuestion'

interface Props {
  question: ConditionalSelectQuestionType
  value: Record<string, any>
  disabled?: boolean
  parentQuestion?: MultipleQuestionType
  setValue: UseFormSetValue<FormResult>
}

export const ConditionalSelectQuestion = ({ question, value, disabled, parentQuestion, setValue }: Props) => {
  useEffect(() => {
    const show = value?.[question.key] === question.showIf

    if (!show) {
      setValue(question.question.key, undefined)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value?.[question.key]])

  return (
    <>
      <SelectQuestion
        question={{
          type: QuestionType.SELECT,
          variants: question.variants,
          title: question.title,
          key: question.key,
          description: question.description,
        }}
        parentQuestionKey={parentQuestion?.key}
        value={value?.[question.key]}
        disabled={disabled}
        setValue={setValue}
      />
      {value?.[question.key] === question.showIf && (
        <FormComponentRenderer
          disabled={disabled}
          key={`conditional_question_${question.question.key}`}
          parentQuestion={parentQuestion}
          currentQuestion={0}
          questions={[question.question]}
          value={value}
          setValue={setValue}
        />
      )}
    </>
  )
}
