import React, { useCallback, useMemo, useRef, useState } from 'react'
import { cn } from 'lib/cn'
import { Search } from 'components/Search'
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown.svg'
import { ReactComponent as GearIcon } from 'assets/icons/gear.svg'

import {
  IRow,
  IFilter,
  ICell,
  isInstanceOfComplexCell,
  IComplexCell,
  TableProps,
  IColumn,
} from 'components/Table/types'
import { DropdownFilter } from './DropdownFilter'
import { filterByKeyValue, filterByQuery } from './filters'
import clsx from 'clsx'
import { TableSettings } from 'components/Modals/TableSettings'

/*
Table component
*/

const renderCell = (cell: ICell): JSX.Element | string => {
  if (isInstanceOfComplexCell(cell)) {
    return (cell as IComplexCell).render
  }
  return cell as string
}

type FilterStatus<T> = {
  [K in keyof T]?: string | number
}

export const Table = <T extends any>({
  title,
  columns,
  rows,
  className,
  filters,
  loading,
  showSearch = true,
  showGrid = false,
  onRowClick,
  orderBy,
  onEmptyState,
  onOrderBy: onOrderByProp,
  onSaveSettings,
}: TableProps<T>) => {
  const [showSettings, setShowSettings] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filterStatus, setFilterStatus] = useState<FilterStatus<T>>({})
  const inputSearchRef = useRef<HTMLInputElement>(null)

  const onSearchChange = (newSearchValue: string) => {
    setSearchQuery(newSearchValue)
  }

  const onFilterDropdownChange = (filter: IFilter<T>, value?: string | number) => {
    if (value) {
      setFilterStatus({ ...filterStatus, [filter.key]: value })
    } else {
      const newFilterStatus = { ...filterStatus }
      delete newFilterStatus[filter.key]
      setFilterStatus(newFilterStatus)
    }
  }

  const filteredRows = useMemo(() => {
    let result: IRow<T>[] = [...rows]

    if (searchQuery && searchQuery.length > 0) {
      result = filterByQuery(searchQuery, result)
    } else if (Object.values(filterStatus).length > 0) {
      Object.entries(filterStatus).forEach(([key, value]) => {
        if (key && value) {
          result = filterByKeyValue(key as keyof T, value as string, result)
        }
      })
    }

    return result
  }, [searchQuery, rows, filterStatus])

  const onOrderBy = (fieldName: keyof T) => {
    if (fieldName === 'action') return
    if (typeof onOrderByProp === 'function') {
      onOrderByProp({
        fieldName,
        order: orderBy?.fieldName === fieldName ? (orderBy.order === 'ASC' ? 'DESC' : 'ASC') : 'ASC',
      })
    }
  }

  const onInputSearchClear = () => {
    if (inputSearchRef.current) inputSearchRef.current.value = ''
    onSearchChange('')
  }

  const onShowSettings = () => {
    setShowSettings(true)
  }

  const oncloseSettings = useCallback(() => setShowSettings(false), [])

  return (
    <>
      <div className={cn('border rounded-md', className)}>
        {(title || showSearch || filters) && (
          <div className={'flex justify-between p-5 items-center'}>
            {title ? <h2 className='text-xl'>{title}</h2> : null}
            <div className='flex gap-2 items-center'>
              <div>
                {showSearch ? (
                  <Search ref={inputSearchRef} onSearch={onSearchChange} onClear={onInputSearchClear} />
                ) : (
                  <></>
                )}
              </div>
              {filters?.map((filter, index) => (
                <DropdownFilter
                  key={index}
                  filter={filter}
                  columns={columns}
                  onChange={(value?: string) => onFilterDropdownChange(filter, value)}
                  value={filterStatus[filter.key] ? String(filterStatus[filter.key]) : ''}
                />
              ))}

              {onSaveSettings && (
                <div>
                  <div role='button' className='cursor-pointer' onClick={onShowSettings}>
                    <GearIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="relative">
          <table className='table'>
            <thead>
            <tr>
              {columns.map(({ label, key }) => (
                <th
                  onClick={() => onOrderBy(key)}
                  key={key.toString()}
                  className='bg-gray-50 hover:bg-gray-100 cursor-pointer'
                >
                  <div className='flex items-center gap-2'>
                    <div
                      className={clsx({
                        'cursor-pointer': typeof onOrderByProp === 'function',
                      })}
                      role='link'
                    >
                      {label}
                    </div>
                    {orderBy?.fieldName === key && (
                      <ArrowIcon
                        className={clsx({
                          'rotate-180': orderBy.order === 'ASC',
                        })}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
            </thead>
            <tbody>
            {filteredRows.length > 0 ? (
              filteredRows.map((row) => (
                <tr
                  className={clsx({
                    'cursor-pointer': !!onRowClick,
                    'active:bg-light-blue': !!onRowClick,
                  })}
                  key={row.id}
                  onClick={onRowClick ? () => onRowClick(row.id) : undefined}
                >
                  {columns.map(({ key }) => (
                    <td
                      className={`text-gray-500 ${showGrid ? 'border border-color-[#EAECF0]' : ''}`}
                      key={key.toString()}
                    >
                      {renderCell(row[key] ?? '')}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <></>
            )}
            </tbody>
          </table>
          {loading && (
            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-70 z-10">
              <div className="loading loading-spinner loading-lg" />
            </div>
          )}
          {filteredRows.length === 0 ? (
            onEmptyState ? (
              onEmptyState
            ) : (
              <div className='flex justify-center h-64 items-center'>Nothing to show</div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>

      {showSettings && (
        <TableSettings
          onClose={oncloseSettings}
          settings={{
            columns: columns as IColumn<string>[],
          }}
          onSave={onSaveSettings}
        />
      )}
    </>
  )
}
