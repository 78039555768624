import * as api from 'data/assignment/api'

export const fetch = (data: any) => {
  return api.fetch(data)
}
export const fetchList = (data: any) => {
	return api.fetchList(data)
}
export const updateStatus = (data: any) => {
  return api.updateStatus(data)
}
export const updateAnswers = (data: any) => {
  return api.updateAnswers(data)
}

export const updateDueDate = (data: any) => {
  return api.updateDueDate(data)
}
