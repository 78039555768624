import {
  FormResult,
  FormUserType,
  MultipleQuestion,
  Question,
  QuestionType,
  Section,
  SectionAnswers,
  SelectQuestion,
} from 'types/dist'

export const getQuestions = (sections: Section[], userType: FormUserType): Question[] =>
  sections
    .filter(({ userType: ut }) =>
      sections.some((x) => x.userType === FormUserType.PHYSICIAN)
        ? ut === userType
        : userType === FormUserType.PHYSICIAN || ut === userType,
    )
    .map(({ questions }) => questions)
    .flat()

export const getScoreWeights = (
  questions: Question[],
  data: FormResult,
): { key: string; value: number | undefined }[] => {
  let results: { key: string; value: number | undefined }[] = []

  for (const question of questions) {
    const answer = data[question.key]

    if (question.type === QuestionType.MULTIPLE) {
      const multipleQuestion = question as MultipleQuestion

      if (answer && typeof answer === 'object') {
        const nestedResults = getScoreWeights(multipleQuestion.questions, answer)

        results = results.concat(nestedResults)
      }
    } else {
      const selectQuestion = question as SelectQuestion

      const variant = selectQuestion.variants.find((v) => v.value === answer)

      const scoreWeight = variant?.scoreWeight

      results.push({ key: question.key, value: scoreWeight })
    }
  }

  return results
}

export const getSectionAnswers = (sections: Section[], data: FormResult): SectionAnswers[] =>
  sections.map((section) => {
    const answers = Object.entries(data).filter(([key, val]) =>
      typeof val === 'object'
        ? section.questions.some((x) => x.key === key) && Object.values(val).some((x) => x)
        : section.questions.some((x) => x.key === key),
    )

    if (answers.length) {
      return Object.fromEntries(answers)
    }

    return null
  })

export const getAnswersFromSections = (sectionAnswers: SectionAnswers[]): FormResult => {
  const answers: FormResult = {}

  sectionAnswers.forEach((section) => {
    if (section) {
      Object.entries(section).forEach(([key, value]) => {
        answers[key] = value
      })
    }
  })

  return answers
}
