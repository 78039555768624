import { get, patch, post } from 'lib/api'
import { toId } from 'lib/utils'

export const fetch = ({ id, ...data }: any = {}) => get(`/patients${toId(id)}`, data)
export const create = ({ clinicId, ...data }: any = {}) => post(`/clinics/${clinicId}/patients`, data)
export const update = ({ id, clinicId, ...data }: any = {}) => patch(`/clinics/${clinicId}/patients${toId(id)}`, data)
export const createUser = ({ id, ...data }: any = {}) =>
  post(`/patients${toId(id)}/create-user`, data)
export const resendInvite = ({ id, ...data }: any = {}) =>
  post(`/patients${toId(id)}/resend-invite`, data)
